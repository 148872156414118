import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <section className="banner-section ">
        <img
          src="/images/about-banner.jpg"
          className="banner-img"
          alt="Banner Image"
        />
        <div className="container">
          <div className="banner-contant">
            <h1>Privacy policy</h1>
            <p class="mb-0">
              Welcome to Fixkart Interio. Your privacy is important to us, and
              we are committed to protecting the personal information you share
              with us. This Privacy Policy explains how we collect, use, and
              safeguard your information.
            </p>
          </div>
        </div>
      </section>
      <section className="seoText">
        <div className="container">
          <h3>Introduction</h3>
          <p>
            Welcome to Fixkart Interio. Your privacy is important to us, and we
            are committed to protecting the personal information you share with
            us. This Privacy Policy explains how we collect, use, and safeguard
            your information.
          </p>
          <h3>Information We Collect</h3>
          <p>We may collect the following types of information:</p>
          <p>
            Personal Information: Name, email address, phone number, and any
            other details provided by you when you contact us or fill out a
            form.
          </p>
          <p>
            Non-Personal Information: Browser type, IP address, time zone, and
            browsing behavior on our website.
          </p>
          <p>
            <b>Cookies:</b> We use cookies to enhance your experience and
            collect analytics data.
          </p>
          <h3>How We Use Your Information</h3>
          <p>The information we collect is used to:
        </p>
        <p>Provide and improve our services.
        </p>
        <p>Respond to your inquiries.
        </p>
        <p>Send updates, promotional offers, and other communications.
        </p>
        <p>Analyze website usage and improve user experience.
        </p>
        <p> Sharing Your Information</p>

        <p>We respect your privacy and do not sell, trade, or rent your personal information to third parties. However, we may share your information with:</p>

        <p> Service providers assisting us in website operations.</p>

        <p> Legal authorities if required by law.</p>

        <h3> Data Security</h3>

        <p> We take reasonable measures to protect your personal information from unauthorized access, alteration, or disclosure.</p>

        <h3>Your Rights</h3>

        <p>You have the right to:</p>

        <p>Access the information we hold about you.</p>

        <p>Request corrections to your personal information.</p>

        <p>Opt out of receiving promotional communications.</p>

        <h3>Third-Party Links</h3>

        <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites and encourage you to review their privacy policies.</p>

        <h3>Updates to this Policy</h3>

        <p> We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.</p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
