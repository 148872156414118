import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <section className="banner-section ">
        <img
          src="/images/about-banner.jpg"
          className="banner-img"
          alt="Banner Image"
        />
        <div className="container">
          <div className="banner-contant">
            <h1>Terms And Conditions</h1>
            <p class="mb-0">
              Welcome to Fixkart Interio! These terms and conditions outline the
              rules and regulations for the use of Fixkart Interio’s website and
              services. By accessing this website or availing of our services,
              you accept these terms and conditions in full. Do not continue to
              use Fixkart Interio’s website or services if you do not agree to
              all the terms and conditions stated on this page.
            </p>
          </div>
        </div>
      </section>
      <section className="seoText">
        <div className="container">
          <h3>Definitions</h3>
          <p>
            <b>Client:</b> Refers to the individual or organization availing
            services from Fixkart Interio.
          </p>
          <p>
            <b>Company:</b> Refers to Fixkart Interio.
          </p>

          <p>
            <b>Services:</b> Refers to interior design, furniture solutions,
            modular kitchens, and related offerings provided by Fixkart Interio.
          </p>

          <h3>Scope of Services</h3>
          <p>
            Fixkart Interio provides customized interior solutions based on the
            Client's requirements. Detailed project scope, timelines, and
            deliverables will be documented in the project agreement signed
            between the Client and Fixkart Interio.
          </p>
          <h3>Payment Terms</h3>
          <p>
            <b>Quotations:</b> All quotations are valid for 30 days unless
            stated otherwise.
          </p>
          <p>
            <b>Advance Payment:</b> An advance payment of 50% of the total
            project cost is required before commencing work.
          </p>
          <p>
            <b>Payment Schedule:</b> Payments will be made as per the milestones
            outlined in the agreement.
          </p>
          <p>
            <b>Delays:</b> Non-payment may result in project delays or
            termination of services.
          </p>
          <h3>Cancellation and Refunds</h3>
          <p>
            <b>Cancellation by Client:</b> Cancellations must be communicated in
            writing. Refunds will be processed as per the agreement and are
            subject to deductions for work completed.
          </p>
          <p>
            <b>Cancellation by Company:</b> Fixkart Interio reserves the right
            to cancel the project under circumstances such as non-payment or
            breach of terms by the Client.
          </p>
          <h3>Intellectual Property</h3>
          <p>
            All designs, drawings, and other creative outputs developed by
            Fixkart Interio remain the property of the Company until full
            payment is received.
          </p>
          <p>
            Unauthorized use of any intellectual property without written
            consent is prohibited.
          </p>
          <h3>Project Timelines</h3>
          <p>
            Fixkart Interio will make reasonable efforts to adhere to project
            timelines. However, delays caused by unforeseen circumstances,
            Client’s revisions, or approvals may extend deadlines.
          </p>
          <p>
            Clients are expected to provide approvals and feedback promptly to
            avoid delays.
          </p>
          <h3>Warranty and Liability</h3>
          <p>
            Fixkart Interio offers a warranty for manufacturing defects on
            furniture and modular components for a specified period, as detailed
            in the agreement.
          </p>
          <p>
            The Company is not liable for damages caused by misuse, improper
            maintenance, or third-party interventions.
          </p>
          <h3>Confidentiality</h3>
          <p>
            Both parties agree to keep confidential any information shared
            during the course of the project.
          </p>
          <p>
            Fixkart Interio may showcase completed projects in its portfolio
            unless otherwise agreed upon in writing by the Client.
          </p>
          <h3>Limitation of Liability</h3>
          <p>
            Fixkart Interio shall not be held liable for any indirect,
            incidental, or consequential damages arising from the use of its
            services.
          </p>
          <h3>Governing Law</h3>
          <p>
            These terms and conditions are governed by the laws of India. Any
            disputes arising will be resolved under the jurisdiction of Jaipur
            courts.
          </p>
          <h3>Amendments</h3>
          <p>
            Fixkart Interio reserves the right to update or modify these terms
            and conditions at any time. Changes will be effective immediately
            upon posting on the website.
          </p>
        </div>
      </section>
    </div>
  );
};

export default TermsAndConditions;
